import { api } from "config/apiHandler";
import cookies from "services/browserStorage/cookies";

const teams = api.injectEndpoints({
  tagTypes: ["Team"],
  endpoints: (build) => ({
    getTeam: build.query({
      query: () => `/teams?current=1`,
      providesTags: ["Team"],
      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    getTeams: build.query({
      query: () => `/teams`,
      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    createTeam: build.mutation({
      query: (requestBody) => ({
        url: "/teams",
        method: "POST",
        body: requestBody,
      }),
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    inviteTeamUser: build.mutation({
      query: (requestBody) => ({
        url: "/teams/invites",
        method: "POST",
        body: requestBody,
      }),
      invalidatesTags: ["Team"],
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    deleteTeamUser: build.mutation({
      query: (requestBody) => ({
        url: `/teams/users/${requestBody.id}`,
        method: "PUT",
        body: { active: 0 },
      }),
      invalidatesTags: ["Team"],
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    deleteTeamInvite: build.mutation({
      query: (requestBody) => ({
        url: `/teams/invites/${requestBody.id}`,
        method: "PUT",
        body: { active: 0 },
      }),
      invalidatesTags: ["Team"],
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    verifyTeamInvite: build.mutation({
      query: (requestBody) => ({
        url: `/teams/invites/verify`,
        method: "POST",
        body: requestBody,
      }),
      invalidatesTags: ["Team"],
      transformResponse: async (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          await cookies.setAuth(baseQueryReturnValue.data);
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTeamQuery,
  useGetTeamsQuery,
  useCreateTeamMutation,
  useInviteTeamUserMutation,
  useDeleteTeamUserMutation,
  useDeleteTeamInviteMutation,
  useVerifyTeamInviteMutation,
} = teams;
