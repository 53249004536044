import { api } from "config/apiHandler";
import { removeAuthSession, setAuthSession } from "store/slices/authSlice";

const auths = api.injectEndpoints({
  endpoints: (build) => ({
    getProviders: build.query({
      query: () => `/auths/providers`,
      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else return baseQueryReturnValue.data;
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    requestAuth: build.mutation({
      query: (requestBody) => ({
        url: "/auths/requests",
        method: "POST",
        body: requestBody,
      }),
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else return baseQueryReturnValue.data;
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    requestSocialAuth: build.query({
      query: (param) =>
        `/auths/requests/social?provider_id=${param.provider_id}&state=${param.state}`,
      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else return baseQueryReturnValue.data;
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    verifyLogin: build.mutation({
      query: (requestBody) => ({
        url: "/auths/verify",
        method: "POST",
        body: requestBody,
      }),
      transformResponse: (baseQueryReturnValue, _, arg) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(setAuthSession(data));
        } catch (error) {
          // Handle error
        }
      },
    }),
    getAuthSession: build.query({
      query: (param) => `/auths/session?provider_id=${param.provider_id}&state=${param.state}`,
      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(setAuthSession(data));
        } catch (error) {
          // Handle error
        }
      },
    }),
    logout: build.mutation({
      query: (requestBody) => ({
        url: "/auths/logout",
        method: "PUT",
        body: requestBody,
      }),
      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(removeAuthSession(data));
          dispatch(api.util.resetApiState()); // reset cache
        } catch (error) {
          // Handle error
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProvidersQuery,
  useRequestAuthMutation,
  useRequestSocialAuthQuery,
  useVerifyLoginMutation,
  useGetAuthSessionQuery,
  useLogoutMutation,
} = auths;
